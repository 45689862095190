<template>
  <div>
    <el-dialog
      @close="closeUploadVisible"
      :before-close="closeUploadVisible"
      center
      title="图片上传" 
      width="1100px" top="5vh"
      :visible.sync="uploadVisible">
      <el-button type="default" @click="group">管理分组</el-button>
      <el-tabs v-model="activeName"  type="card" @tab-click="tabClick">
        <el-tab-pane v-for="(item,index) in groupList" :key="item.group_id" :label="item.group_name" :name="item.group_name">
          <div class="img_view">
            <div v-for="(items,indexs) in item.list" :key="indexs" class="img_item">
              <img @click="chooseImg(index, indexs)" :src="items.img_path" alt="">
              <div class="IMGname">{{items.img_name}}</div>
              <div v-show="items.bOn" class="choose" @click="chooseImg(index,indexs)"><i class="el-icon-check chooseIcon"></i></div>
              <i @click.stop="removeImg(items)" class="el-icon-delete delIcon"></i>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-row>
        <el-col :span="12">
          <div class="operation">
            <div>
              <el-checkbox v-model="chooseAll" @change="allChange">全选</el-checkbox>
              <el-divider direction="vertical"></el-divider>
              <span>已选</span><span class="chooseColor">{{chooseList.length}}</span><span>可选</span><span class="chooseColor">{{chooseImgCount}}</span>
              <el-button size="mini" type="primary" @click="subImg">确定</el-button>
              <el-button size="mini" type="danger" @click="removeChooseImg">删除</el-button>
              <el-divider direction="vertical"></el-divider>
            </div>
            <el-upload
              ref="upload"
              style="width:100px"
              list-type="picture"
              action="https://jsonplaceholder.typicode.com/posts/"
              accept="image/*"
              :auto-upload="false"
              :show-file-list="false"
              :multiple = true
              :on-change="handlerChange">
              <el-button size="mini" type="primary">上传</el-button>
            </el-upload>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right; margin: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.pagenum"
              :page-size="queryInfo.pagesize"
              layout="total, prev, pager, next"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog :visible.sync="groupVisible" width="25%">
      <div class="between" v-for="(item,index) in groupList" :key="index">
        <div><i class="el-icon-folder"></i><span @click="updateGroupName(index, item.group_id)">{{item.group_name}}</span></div>
        <div v-show="index!=0"><el-checkbox v-model="item.checked"></el-checkbox></div>
      </div>
      <div class="around">
        <el-button size="small" type="primary" plain @click="groupVisible=false">取消</el-button>
        <el-button size="small" type="danger" :disabled="groupList.length==1" @click="removeGroup">删除</el-button>
        <el-button size="small" type="primary" @click="addGroup">新增</el-button>
      </div>
    </el-dialog>
    <el-dialog
      width="30%"
      title="请输入分组名称"
      :visible.sync="addGroupVisible">
      <el-input v-model="groupItemName"></el-input>
      <div style="text-align:center;margin-top:15px">
        <el-button type="default" @click="closeAddGroupVisible">取消</el-button>
        <el-button type="primary" @click="subAdd">确定</el-button>
      </div>
    </el-dialog> 
  </div>
</template>

<script>
let that;
import axios from 'axios'
import { simpleDebounce } from '@/api/utils'
export default {
  name:'Upload',
  props:{
    uploadVisible:{
      type:Boolean,
      default(){
        return false
      }
    },
    pictureType:{
      type:String,
      default(){
        return ''
      }
    },
    chooseImgCount:{
      type:Number,
      default(){
        return 0
      }
    }
  },
  data () {
    return {
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      total:0,
      activeName:'未分组',
      activeIndex:0,
      group_id: 0,
      groupList:[
        {
          name:'未分组',
          checked:false,
          list:[]
        },{
          name:'测试',
          checked:false,
          list:[]
        }
      ],
      groupVisible:false,
      addGroupVisible:false,
      groupItemName:'',
      addGroupId: 0,
      addGroupIndex:-1,
      chooseAll:false,
      isHover:-1,
      chooseList:[]
    }
  },
  watch:{
    activeName(){
    	this.chooseAll = false;
    	this.chooseList = [];
      for(let i in this.groupList){
        if(this.groupList[i].group_name==this.activeName){
        	if(this.group_id!=this.groupList[i].group_id){
        		this.group_id = this.groupList[i].group_id;
        		this.getPictureList();
        	}
          return this.activeIndex = i;
        }
      }
    },
    uploadVisible(){
      if(this.uploadVisible===true){
        this.groupList[this.activeIndex].list.forEach(item=>item.bOn=false)
        this.chooseList.splice(0,this.chooseList.length)
      }
    }
  },
  created() {
  	that = this;
  	this.getPictureList();
  },
  mounted() {
    this.groupList.map(function(value){
      value.list.forEach(item=>{
        item.bOn=false
      })
    })
  },
  methods:{
  	getPictureList(){
				var url = 'picture/picture_list';
				let params = {
					group_id: this.group_id,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.groupList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
  	},
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = newSize;
      this.getPictureList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getPictureList();
    },
    //获取图片数量
    getTotal(){
      for(let i =0;i<this.groupList.length;i++){
        if(this.groupList[i].name==this.activeName){
          this.total=this.groupList[i].list.length
          return 
        }
      }
    },
    //管理分组弹窗
    group(){
      this.groupVisible=true
    },
    //添加分组弹窗
    addGroup(){
      this.group_id = 0;
    	this.addGroupId = 0;
      this.addGroupVisible = true;
    },
    //修改分组名字
    updateGroupName(index, group_id){
      if(index>0){
      	this.addGroupId = group_id;
      	this.group_id = group_id;
        this.addGroupIndex = index;
        this.addGroupVisible = true;
      }
    },
    //关闭分组弹窗
    closeAddGroupVisible(){
      this.groupItemName = '';
      this.addGroupVisible = false;
    },
    //删除分组
    removeGroup(){
      that.$confirm('你确定要删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      	let group_ids = [];
      	for(let i=0;i<that.groupList.length;i++){
      		if(that.groupList[i].checked) group_ids.push(that.groupList[i].group_id);
      	}
				var url = 'picture/deleteGroup';
				let params = {
					group_ids: group_ids,
				};
				that.fd_post(url, params).then((res) => {
					if(res.status) {
						that.groupList=that.groupList.filter(item=>item.checked==false);
						that.$message.success('删除成功');
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
      }).catch(() => {
        that.$message.info('已取消删除');          
      });
      that.groupVisible = false;
    },
    //确认分组
    subAdd(){
      if(!this.groupItemName){
        this.$message.warning('请输入分组名字!')
      }else{
        for(let i=0;i<this.groupList.length;i++){
          if(this.groupList[i].group_name==this.groupItemName){
            this.$message.error('已存在同名分组')
            return this.groupItemName='',this.addGroupVisible=false;
          }
        }
        var url = 'picture/addOrUpdateGroup';
				let params = {
					group_id: this.group_id,
					group_name: this.groupItemName,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						if(that.addGroupId>0){
							that.groupList[that.addGroupIndex].group_name=res.info.group_name;
						}else{
							that.groupList.push({group_id:res.info.group_id,group_name:res.info.group_name,checked:false,list:[]});
						}
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
      }
      this.groupItemName = '';
      this.groupVisible = false;
      this.addGroupVisible = false;
    },
    closeUploadVisible(){
      this.$emit('close')
    },
    //选择图片
    chooseImg(index,indexs){
      const currentNumber=(this.queryInfo.pagenum-1)*this.queryInfo.pagesize+indexs
      const temp = this.groupList[index].list[currentNumber]
      if(temp.bOn){
        this.chooseAll=false
        temp.bOn=false
        this.chooseList=this.chooseList.filter(item=>item.bOn==true)
      }else if(this.chooseList.length<this.chooseImgCount){
        this.chooseAll=true
        temp.bOn = !temp.bOn
        this.chooseList.push(temp)
      }else{
        this.$message.warning(`最多选择${this.chooseImgCount}张照片`)
      }
      this.$set(this.groupList[index].list,currentNumber,temp)
    },
    //tabs切换
    tabClick(){
      
    },
    //删除图片
    removeImg(item){
      this.$confirm('你确认要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let img_ids = [];
        img_ids.push(item.img_id);
        var url = 'picture/delete_picture';
				let params = {
					img_ids: img_ids,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.chooseAll = false;
    				that.chooseList = [];
						that.$message.success("删除成功");
						that.getPictureList();
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
      }).catch(() => {
        this.$message.info('已取消删除');          
      });
    },
    //全选
    allChange(res)  {
      if(res==false){
        this.groupList[this.activeIndex].list.forEach(item=>item.bOn=false)
        this.chooseList.splice(0,this.chooseList.length)
        return
      }else if(this.chooseList.length>0){
        this.groupList[this.activeIndex].list.forEach(item=>item.bOn=false)
        this.chooseList.splice(0,this.chooseList.length)
        this.allChange(true)
      }
      const currentLeft=(this.queryInfo.pagenum-1)*this.queryInfo.pagesize
      const currentRight=this.queryInfo.pagenum*this.queryInfo.pagesize
      for(let item of this.groupList[this.activeIndex].list.slice(currentLeft,currentRight)){
        if(this.chooseList.length<this.chooseImgCount&&this.chooseList.length<this.groupList[this.activeIndex].list.length){
          item.bOn=true
          this.chooseList.push(item)
        }else{
          return
        }
      }
      
    },
    //上传
    handlerChange:simpleDebounce(function(){
      const uploadFiles=this.$refs.upload.uploadFiles;
      let upload_files = [];
      uploadFiles.map(function(value){
        upload_files.unshift({
          name: value.name,
          raw: value.raw,
        });
      });
      var url = 'picture/upload_picture';
			var formData = new FormData();
			formData.append("group_id", this.group_id);
			for (var i = 0; i < upload_files.length; i++) {
	        formData.append("file[]", upload_files[i].raw, upload_files[i].name);
	    }
    	axios({
          url:url,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          method: "post",
          data: formData,
    	}).then(res => {
    			that.$refs.upload.clearFiles();
    			if(res.status){
    				that.$message.success('上传成功');
    				that.getPictureList();
    			}else{
    				that.$message.error(res.msg);
    			}
    	}).catch(error => {
    			that.$refs.upload.clearFiles();
          that.$message.error('网络错误');
    	})
    },1000),
    //确认
    subImg(){
      this.chooseAll = false;
      console.log(this.pictureType);
      this.$emit('receiveRes',this.chooseList, this.pictureType)
    },
    //删除
    removeChooseImg(currentNumber){
      if(this.chooseList.length==0)return 
      this.$confirm('你确认要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      	let img_ids = [];
      	for(let i=0; i<that.chooseList.length; i++){
      		img_ids.push(that.chooseList[i].img_id);
      	}
        var url = 'picture/delete_picture';
				let params = {
					img_ids: img_ids,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.chooseAll = false;
    				that.chooseList = [];
						that.$message.success("删除成功");
						that.getPictureList();
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
      }).catch(() => {
        this.$message.info('已取消删除');          
      });
    }

  }
}
</script>

<style lang="scss" scoped>
@import './Upload.scss';
</style>